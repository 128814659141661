<template>
  <div class="loader-dots"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loader-dots {
  display: inline-block;

  &::before {
    display: inline-block;
    white-space: pre;
    min-width: 20px;
    text-align: left;
    content: ".....";
    -webkit-animation: numberEllipsis 1s linear infinite;
    animation: numberEllipsis 1s linear infinite;
  }
}
</style>