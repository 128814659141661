<template>
  <div class="split-price">
    <h3 class="text-align-center margin-top-40 margin-bottom-40">{{ title }}</h3>
    <div class="box">
      <div class="color" :style="_color_style"></div>
      <div class="header">
        <div class="product">
          <div class="logo" :style="_logo1_style"></div>
          <div class="title">{{ product1.title }}</div>
          <div v-if="product1.price !== null" class="price">{{ product1.price | money }}</div>
          <div v-if="product1.price === null" class="price"><loader-dots /></div>
          <div class="suffix">kr./md.</div>
        </div>
        <div class="coverages mobile">
          <div>
            <div v-for="(coverage, i) in product1.coverages" :key="`product1-coverage-${i}`" :class="{ coverage: true, mdl: coverage.description }" @click="_modal(coverage)">{{ coverage.title | renderText(data) }}</div>
          </div>
        </div>
        <div class="mobile" style="position: relative; height: 32px; margin-bottom: 30px;">
          <div class="plus">+</div>
        </div>
        <div class="product">
          <div class="logo" :style="_logo2_style"></div>
          <div class="title">{{ product2.title }}</div>
          <div v-if="single" class="price">-</div>
          <div v-else-if="product2.price !== null" class="price">{{ product2.price | money }}</div>
          <div v-else class="price"><loader-dots /></div>
          <div class="suffix">{{ single ? "Leje af ladeboks findes allerede i din indkøbskurv." : "kr./md." }}</div>
        </div>
        <div class="coverages mobile">
          <div>
            <div v-for="(coverage, i) in product2.coverages" :key="`product2-coverage-${i}`" :class="{ coverage: true, mdl: coverage.description }" @click="_modal(coverage)">{{ coverage.title | renderText(data) }}</div>
          </div>
        </div>
        <div class="plus desktop">+</div>
      </div>
      <div class="body desktop">
        <div class="coverages">
          <div>
            <div v-for="(coverage, i) in product1.coverages" :key="`product1-coverage-${i}`" :class="{ coverage: true, mdl: coverage.description }" @click="_modal(coverage)">{{ coverage.title | renderText(data) }}</div>
          </div>
          <div>
            <div v-for="(coverage, i) in product2.coverages" :key="`product2-coverage-${i}`" :class="{ coverage: true, mdl: coverage.description }" @click="_modal(coverage)">{{ coverage.title | renderText(data) }}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="price" v-if="(single && product1.price !== null) || (product1.price !== null && product2.price !== null)">
          <span class="total">Samlet pris:</span> {{ (single ? product1.price : product1.price + product2.price) | money }} <span class="suffix">kr./md.</span>
        </div>
        <div class="price" v-else>
          <span class="total">Samlet pris:</span> <loader-dots /> <span class="suffix">kr./md.</span>
        </div>
        <div v-if="totalSubHeading" class="subheading">{{ totalSubHeading }}</div>
        <div v-if="remarks" class="remarks">{{ remarks }}</div>
      </div>
    </div>
    <div v-if="link" class="display-block text-align-center margin-top-30">
      <a :href="link" target="_blank" class="font-size-16">{{ linkLabel }}</a>
    </div>
  </div>
</template>

<script>
import LoaderDots from './LoaderDots.vue';

export default {
  components: {
    LoaderDots
  },
  props: {
    single: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: "#3F3666",
    },
    title: {
      type: String,
      requried: true,
    },
    product1: {
      type: Object,
      required: true,
    },
    product2: {
      type: Object,
      required: true,
    },
    totalSubHeading: {
      type: String,
      default: null,
    },
    remarks: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    linkLabel: {
      type: String,
      default: "Læs mere om vores pakker",
    },
  },
  methods: {
    normCoverage(coverage) {
      const coverageSchema = {
        title: null,
        description: null,
      };

      return { ...coverageSchema, ...coverage };
    },
    normProduct(product) {
      const self = this;

      const productSchema = {
        logo: null,
        logoScale: 1,
        title: null,
        price: null,
        coverages: [],
      };

      product = { ...productSchema, ...product };

      product.coverages = product.coverages.map((coverage) => {
        return self.normCoverage(coverage);
      });

      return product;
    },
    _modal(coverage) {
      if (coverage.description) this.showModal(this.$options.filters.renderText(coverage.title, this.data), this.$options.filters.renderText(coverage.description, this.data));
    }
  },
  computed: {
    _product1() {
      return this.normProduct(this.product1);
    },
    _product2() {
      return this.normProduct(this.product2);
    },
    _color_style() {
      return {
        background: `linear-gradient(90deg, #3F3666 3.84%, ${this.color} 99.47%)`,
      };
    },
    _logo1_style() {
      return {
        backgroundImage: `url(${this._product1.logo})`,
        transform: `scale(${this._product1.logoScale})`,
      };
    },
    _logo2_style() {
      return {
        backgroundImage: `url(${this._product2.logo})`,
        transform: `scale(${this._product2.logoScale})`,
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.split-price {
  & > .box {
    position: relative;
    display: block;
    background-color: white;
    margin-bottom: 2rem;
    border: 1px solid #3f3666;
    border-radius: 0.8rem;
    overflow: hidden;
    padding: 0;

    & > .color {
      width: 100%;
      height: 10px;
    }

    & > .header {
      position: relative;
      padding: 30px;
      border-bottom: 1px solid #E0E0E0;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
      }

      & > .product {
        text-align: center;

        & > .logo {
          width: 100%;
          height: 40px;

          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }

        & > .title {
          font-family: 'PT Serif';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          color: #424242;
          padding: 20px 0 20px 0;
        }

        & > .price {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 800;
          font-size: 27px;
          line-height: 32px;
          color: #424242;
        }

        & > .suffix {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #424242;
          opacity: 0.7;
          padding-top: 5px;
        }
      }

      & > .plus, & > div > .plus {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        font-size: 27px;
        line-height: 32px;
        color: #424242;

        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        pointer-events: none;
      }

      & > .plus, & > div > .plus {
        font-size: 38px;
      }
    }

    & > .body, & > .header {
      padding: 30px 0 30px 0;
      border-bottom: 1px solid #E0E0E0;

      & > .title {
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #424242;
        text-align: center;
      }

      & > .coverages {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0;

        @media screen and (max-width: 767px) {
          grid-template-columns: 1fr;
        }

        & > div {
          margin-bottom: -20px;
          padding: 30px;
          justify-self: center;

          & > .coverage {
            position: relative;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 13px;
            color: #424242;
            margin: 0 0 20px 0;
            white-space: nowrap;

            &.mdl {
              cursor: pointer;
              padding: 0 26px 0 0;

              &::after {
                position: absolute;
                font-family: "Font Awesome 6 Pro";
                font-weight: 300;
                content: "\f059";
                text-align: center;
                color: #c2c2c2;
                padding: 0;
                top: 0;
                right: 0;
              }
            }

            &::before {
              display: inline-block;
              font-family: "Font Awesome 6 Pro";
              font-weight: 300;
              content: "\f00c";
              width: 1.2rem;
              text-align: center;
              margin-right: 1rem;
            }
          }
        }
      }
    }

    & > .footer {
      padding: 30px;
      background-color: #F3F3F3;

      & > .price {
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 27px;
        line-height: 32px;
        color: #424242;

        & > .total {
          font-family: 'PT Serif';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          color: #424242;
        }

        & > .suffix {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #424242;
        }
      }

      & > .subheading {
        text-align: center;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #424242;
        padding-top: 10px;
      }

      & > .remarks {
        text-align: center;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #424242;
        padding-top: 25px;
      }
    }
  }

  & .desktop {
    @media screen and (max-width: 767px) {
      display: none ! important;
    }
  }

  & .mobile {
    @media screen and (min-width: 768px) {
      display: none ! important;
    }
  }
}
</style>
