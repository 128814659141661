<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">Elbil Plus-pakke</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <form id="step1" v-on:submit.prevent="submitStep1">
            <!-- Registreringsnummer -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="!product.fields.registrationToggle.value"
                :data="product.fields.registrationNumber"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Toggle knap for registreringsnummer -->
            <FormInput
              :data="product.fields.registrationToggle"
              :product="product"
              v-on:formFunction="formFunction"
            />

            <!-- Information om bil -->
            <VehicleInformation
              :data="product.fields.registrationData"
              :product="product"
            />

            <!-- Vehicle brand -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.vehicleBrand"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Vehicle model -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.vehicleModel"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Model year -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.modelYear"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Fuel type -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.fuelType"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Vehicle variant -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.vehicleVariant"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>
          </form>
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <div class="container small">
        <div class="box">
          <form id="step2" v-on:submit.prevent="submitStep2">
            <!-- Felter på trin 2 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 2"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </form>
        </div>
        <!-- Fejlbesked -->
        <message
          v-show="error.step2"
          :message="error.step2"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 3">
      <form id="step3" v-on:submit.prevent="submitStep3">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 3 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-show="field.step == 3"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Split price -->
        <div id="packages" class="container small">
          <split-price
            :single="_has_charger_in_cart"
            :data="product"
            title="Elbilforsikringen og ladeløsningen dækker"
            color="#FF0097"
            :product1="_product1"
            :product2="_product2"
            :totalSubHeading="_price_subheading"
            remarks="Bemærk: Elbilforsikringen opkræves af Aros Forsikring, og ladeløsningen opkræves af AURA"
            link="https://www.aura.dk/ladeboks-til-din-elbil/ElbilPlus/?gclid=EAIaIQobChMI2PzjiuGs-AIVLgWiAx0hUAxAEAAYASAAEgJZ3vD_BwE"
            linkLabel="Læs mere om Elbil Plus-pakken"
          />
        </div>

        <!-- Extra packages -->
        <coverages
          v-model="product.coverages"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <div class="container small">
          <!-- Infobesked -->
          <message
            :message="_info_box"
            type="info"
            v-on:formFunction="formFunction"
          />
        </div>
      </form>
    </template>

    <div class="container small">
      <div class="button-flex-container">
        <!-- Back button -->
        <button
          v-if="product.step > 1"
          type="button"
          class="button white justify-start"
          v-on:click.prevent="product.step -= 1"
        >
          Tilbage
        </button>

        <!-- Button step 1 -->
        <button
          v-if="product.step == 1"
          type="submit"
          class="button"
          form="step1"
          :disabled="!requirementsStep1Met || loading.step1"
        >
          <template v-if="!loading.step1">Næste</template>
          <template v-else-if="loading.step1">
            <i class="fa-light fa-spinner-third fa-fw icon-spin" />
          </template>
        </button>

        <!-- Button step 2 -->
        <button
          v-if="product.step == 2"
          type="submit"
          class="button"
          form="step2"
          :disabled="!requirementsStep2Met || loading.step2"
        >
          <template v-if="!loading.step1">Næste</template>
          <template v-else-if="loading.step2">
            <i class="fa-light fa-spinner-third fa-fw icon-spin" />
          </template>
        </button>

        <!-- Button step 3 -->
        <button
          v-if="product.step == 3"
          type="submit"
          class="button"
          form="step3"
          :disabled="!requirementsStep3Met"
        >
          <template v-if="existingPolicy">Opdater indkøbskurv</template>
          <template v-else>Tilføj til indkøbskurv</template>
        </button>
      </div>
    </div>

    <!-- Product footer -->
    <product-footer
      altTitle="Mere om Elbil Plus-pakken"
      :productId="product.productId"
      :productLink="product.productLink"
      :productName="product.productName"
      conditionsLink="https://www.aura.dk/ladeboks-til-din-elbil/ElbilPlus/vilkaar-og-betingelser/"
      v-on:contactFromFooter="messageBox"
    >
      Med en samlet Elbil Plus-pakke er det enkelt og billigt at få styr på alt det nødvendige til din elbil. Sammen med energiselskabet AURA tilbyder vi elbilforsikring og ladeløsning fra kun 599 kr./md. Elbil Plus-pakken indeholder en elbilforsikring med kasko- og ansvarsforsikring samt standardinstallation af ladeboks, eventuel udskiftning af nuværende ladeboks, serviceaftale på ladeboks, attraktiv elaftale og 100% afgiftsrefusion på {{ product.refusion }}/kWh.
    </product-footer>
  </div>
</template>
<script>
import M92 from "@/assets/json/M92.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import VehicleInformation from "@/components/VehicleInformation.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import SplitPrice from "@/components/SplitPrice.vue";
import coverages from "@/components/coverages.vue";
import debounce from "lodash/debounce";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";

export default {
  name: "Elbilforsikring",
  components: {
    FormInput,
    progressIndicator,
    VehicleInformation,
    message,
    productFooter,
    SplitPrice,
    coverages,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      productId: "M92",
      product: this.copy(M92),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  mixins: [commonProduct, validateData],
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Set registration number from input
      this.product.fields.registrationNumber.value = this.$route.query.input;

      // If value is set, run function
      if (this.product.fields.registrationNumber.value) {
        this.getRegistrationNumber();
      }

      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      this.$router.push({ name: "Bilforsikring", query: query });
    }
  },
  computed: {
    _has_charger_in_cart() {
      for (const item of this.$store.state.basket) {
        if (!("packages" in item)) continue;
        if ("aura" in item.packages) return true;
      }

      return false;
    },
    _info_box() {
      return {
        headline: "Har du mere end én elbil?",
        text: "Hvis du har mere end én elbil og kun behov for én ladeboks, er dette selvfølgelig en mulighed. Bestiller du to eller flere Elbil Plus-pakker, vil du derfor kun blive opkrævet for én ladeboks, men stadig opnå de unikke forsikringsfordele for samtlige indtegnede elbiler. Har du brug for rådgivning, er du velkommen til at udfylde vores kontaktformular.",
        button: {
          text: "Kontakt mig",
          function: "messageBox",
        },
      };
    },
    _product1() {
      let product = this.product.products.product1;
      product.price = this.product.packages.standard.price === null ? null : this.product.packages.standard.price.totalMonthlyPrice;

      return product;
    },
    _product2() {
      let product = this.product.products.product2;
      product.price = this.product.packages.aura.price === null ? null : this.product.packages.aura.price.totalMonthlyPrice;

      return product;
    },
    _price_subheading() {
      return `Selvrisiko: ${this.$options.filters.moneyShort(this.product.fields.excess.value)} kr.`;
    },
    /*
     * Check requirements for step 1
     */
    requirementsStep1Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.step == 1) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
    /*
     * Check requirements for step 2
     */
    requirementsStep2Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.step == 2) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
    /*
     * Check requirements for step 2
     */
    requirementsStep3Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.step == 3) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      if (!this.product.package || this.product.package.loading) {
        return false;
      }
      return true;
    },
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (!value.value || value.error) {
            this.cerror('Not all requirements met', value);

            return false;
          }
        }
      }
      return true;
    },
  },
  watch: {
    "product.fields.vehicleBrand.value": {
      handler(n) {
        // Tesla == 122
        const val = n == 122 ? 7000 : 4000;
        const id = n == 122 ? 2 : 1;

        if (this.product.fields.excess.value != val) {
          this.product.fields.excess.value = val;
          this.product.fields.excessId.value = id;
        }
      },
    },
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.step": {
      handler(o, n) {
        // Reset validation error
        this.validationError = null;

        // Reset error message
        this.error[`step${o}`] = null;
        this.error[`step${n}`] = null;
      }
    },
  },
  methods: {
    invalidVariant() {
      // Invalid choice of car
      if (this.product.fields.age.value < 18) {
        this.error[`step${this.product.step}`] = {
          headline: "Elbil Plus-pakken er målrettet elbiler",
          text: "Fordi din bil ikke udelukkende kører på el, kan vi ikke tilbyde dig en Elbil Plus-pakke. Vi henviser dig i stedet til vores almindelige bilforsikring her på webshoppen. Har du brug for personlig rådgivning, kan du udfylde vores kontaktformular. Du vil herefter blive kontaktet af vores kundeservice med et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }
    },
    /*
     * Reset zipcode
     */
    resetZipcode() {
      this.product.fields.zipcode.value = null;
    },
    /*
     * Set zipcode
     */
    setZipcode(value) {
      this.product.fields.zipcode.value = value.postnummer.nr;
    },
    /*
     * Get data for registration number from api
     */
    getRegistrationNumber() {
      let self = this;

      // Reset car fields
      self.resetCarFields();

      // Remove error if it's there
      self.product.fields.registrationNumber.error = null;

      // Set registration field to loading
      self.product.fields.registrationNumber.loading = true;

      // Create api string
      let apiString = `/api/vehicle/information/${self.product.fields.registrationNumber.value}`;

      // Get options list
      self.axios
        .get(apiString, {
          headers: {
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then((response) => {
          self.product.fields.registrationData.value = response.data;
          self.setFromData(response.data.data, self.product, {
            'effectFactor': "effectFactorCalculated"
          });
          self.product.fields.registrationNumber.loading = false;
        })
        .then(() => {
          // Track data for retargeting
          self.trackData(
            "registrationNumber",
            self.product,
            self.$store.state.basket
          );

          // Check if step requirements are met
          if (!self.requirementsStep1Met) {
            self.error.step1 = {
              headline: "Vi kan desværre ikke beregne en pris online",
              text: 'En af de kritiske informationer vedr. køretøjet mangler fra SKAT for at kunne beregne en pris online. Indtast oplysningerne om din bil manuelt ved at trykke på knappen "Jeg kender ikke registreringsnummeret", eller udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.',
              button: {
                text: "Kontakt mig",
                function: "messageBox",
              },
            };
          }
        })
        .catch(() => {
          self.product.fields.registrationNumber.error = `Vi kunne ikke finde køretøjsinformation for "${self.product.fields.registrationNumber.value.toUpperCase()}". Dobbelttjek at du har tastet det rigtige nummer ind i feltet.\nHvis du ikke kender din nummerplade, kan du finde din bil manuelt ved at trykke på knappen nedenfor`;
          self.product.fields.registrationNumber.loading = false;
        });
    },
    /*
     * Reset car fields that is updated from registration field
     */
    resetCarFields() {
      this.product.fields.chassisNumber.value = null;
      this.product.fields.chassisType.value = null;
      this.product.fields.effectFactor.value = null;
      //this.product.fields.fuelType.value = null;
      this.product.fields.horsepower.value = null;
      this.product.fields.originalPrice.value = null;
      this.product.fields.use.value = null;
      this.product.fields.modelYear.value = null;
      this.product.fields.vehicleBrand.value = null;
      this.product.fields.vehicleName.value = null;
      this.product.fields.vehicleModel.value = null;
      this.product.fields.vehicleVariant.value = null;
      this.product.fields.weightOwn.value = null;
      this.product.fields.wheelDrive.value = null;
      this.product.fields.registrationData.value = null;
      this.error.step1 = null;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Remove error
      self.error.price = null;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then(function (response) {
          // If weird error shows, run function again
          //if (response.data.objects && response.data.objects.length < 3) {
          //self.calculateEveryPrice();
          //}

          self.insertPrice(response.data);

          // Select Std price
          self.choosePackage(self.product.packages.standard);

          // Track data for retargeting
          self.trackData(
            "calculatePrice",
            self.product,
            self.$store.state.basket
          );
        })
        .then((err) => {
          this.cerror("Price calc err", err);

          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Set data when you choose vehicleVariant
     */
    vehicleVariantSelected() {
      this.error.step1 = null;

      this.setFromData(this.product.fields.vehicleVariant.selected.data, this.product, {
        'effectFactor': "effectFactorCalculated"
      });

      if (!this.requirementsStep1Met) {
        this.error.step1 = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }
    },
    /*
     * Function for input on registration number
     */
    registrationNumber() {
      this.resetCarFields();
      this.product.fields.registrationNumber.error = null;
      this.error.step1 = null;

      // If the registration number is filled out, change to upper case
      if (this.product.fields.registrationNumber.value) {
        this.product.fields.registrationNumber.value =
          this.product.fields.registrationNumber.value.toUpperCase();
      }
    },
    /*
     * When setting vehicle brand, reset other car fields
     */
    setVehicleBrand() {
      this.product.fields.vehicleModel.value = null;
      this.product.fields.modelYear.value = null;
      //this.product.fields.fuelType.value = null;
      this.product.fields.vehicleVariant.value = null;
    },
    /*
     * Function when the toggle for registration number changes
     */
    toggleRegistrationData() {
      // Reset car fields
      this.resetCarFields();

      if (this.product.fields.registrationToggle.value) {
        // Remove required from registration number
        this.product.fields.registrationNumber.required = false;

        // Remove required from registration number
        this.product.fields.registrationData.required = false;

        // Remove content of registration number
        this.product.fields.registrationNumber.value = null;
        this.product.fields.registrationNumber.error = null;

        // Set vehicle fields to select
        this.product.fields.vehicleBrand.type = "Select";
        this.product.fields.vehicleModel.type = "Select";
        this.product.fields.modelYear.type = "Select";
        //this.product.fields.fuelType.type = "Select";
        this.product.fields.vehicleVariant.type = "Select";
      } else {
        // Add required from registration number
        this.product.fields.registrationNumber.required = true;

        // Add required from registration number
        this.product.fields.registrationData.required = true;

        // Set vehicle fields to hidden
        this.product.fields.vehicleBrand.type = "Hidden";
        this.product.fields.vehicleModel.type = "Hidden";
        this.product.fields.modelYear.type = "Hidden";
        //this.product.fields.fuelType.type = "Hidden";
        this.product.fields.vehicleVariant.type = "Hidden";
      }
    },
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      //*****************************************
      // CLEANUP
      //*****************************************

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      //*****************************************
      // MESSAGE BOXES
      //*****************************************

      //*****************************************
      // GENERIC SUBMIT
      //*****************************************

      // Set step 1 to loading
      self.loading.step1 = true;

      // Validate the data from step 1
      let validationResult;

      try {
        validationResult = await self.validateData(
          self.product,
          self.product.step
        );
      } catch (err) {
        //*****************************************
        // MESSAGE BOXES DEPENDENT OF VALIDATION
        //*****************************************

        // No variants
        if (this.product.fields.registrationToggle.value && this.product.fields.vehicleVariant.error) {
          self.error[`step${self.product.step}`] = {
            headline: "Elbil Plus-pakken er målrettet elbiler",
            text: "Fordi din bil ikke udelukkende kører på el, kan vi ikke tilbyde dig en Elbil Plus-pakke. Vi henviser dig i stedet til vores almindelige bilforsikring her på webshoppen. Har du brug for personlig rådgivning, kan du udfylde vores kontaktformular. Du vil herefter blive kontaktet af vores kundeservice med et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };
        }

        return;
      }

      //*****************************************
      // LAST OF GENERIC SUBMIT
      //*****************************************

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.loading.step1 = false;
    },
    /*
     * Submit step 2 in form
     */
    async submitStep2() {
      let self = this;

      //*****************************************
      // CLEANUP
      //*****************************************

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      //*****************************************
      // MESSAGE BOXES
      //*****************************************

      // Age < 18
      if (this.product.fields.age.value < 18) {
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Ud fra den oplyste alder, kan vi desværre ikke beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      //*****************************************
      // GENERIC SUBMIT
      //*****************************************

      // Set step 1 to loading
      self.loading.step2 = true;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set step 1 to loading
      self.loading.step2 = false;
    },
    /*
     * Submit step 3 in form
     */
    submitStep3() {
      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
